import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  sliderRoot: {
    [theme.breakpoints.down('xs')]: {
      height: '1.6rem',
    },
    position: 'relative',
    height: '2rem',
    width: '100%',
    backgroundColor: '#434343',
    padding: '0 0.3rem',
    boxSizing: 'content-box',
    color: '#CAFF73',
    display: 'flex',
    alignItems: 'center',
    touchAction: 'none',
  },
  sliderTrack: {
    [theme.breakpoints.down('xs')]: {
      height: '1.9rem',
    },
    position: 'absolute',
    height: '2.3rem',
    borderRadius: '0',
    border: 'none',
    backgroundColor: '#434343',
    borderBottomWidth: '2px',
    borderBottomColor: '#fff',
    borderBottomStyle: 'solid',
    borderTopWidth: '2px',
    borderTopColor: '#fff',
    borderTopStyle: 'solid',
    padding: '0 0.3rem',
    marginLeft: '-0.3rem',
    boxSizing: 'content-box',
  },
  sliderThumb: {
    [theme.breakpoints.down('xs')]: {
      height: '2.1rem',
    },
    position: 'absolute',
    zIndex: 10,
    marginTop: '0',
    height: '2.55rem',
    backgroundColor: '#fff',
    boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.40)',
    cursor: 'pointer',
    transform: 'translateX(-50%)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'inherit',
    },
  },
  thumbLeft: {
    [theme.breakpoints.down('xs')]: {
      borderTop: '0.65rem solid #fff',
      borderBottom: '0.65rem solid #fff',
      marginLeft: '-0.3rem',
    },
    width: '0.8rem',
    backgroundColor: '#3F3F3F',
    border: '0.35rem solid #fff',
    borderTop: '0.75rem solid #fff',
    borderBottom: '0.75rem solid #fff',
    borderRadius: '0.3rem 0 0 0.3rem',
    marginLeft: '-0.7rem',
  },
  thumbMiddle: {
    [theme.breakpoints.down('xs')]: {
      height: '2.6rem',
    },
    height: '3rem',
    width: '0.6rem',
    backgroundColor: '#fff',
    borderRadius: '1rem',
    marginLeft: '0',
    zIndex: '40',
  },
  thumbRight: {
    [theme.breakpoints.down('xs')]: {
      borderTop: '0.65rem solid #fff',
      borderBottom: '0.65rem solid #fff',
      marginLeft: '0.3rem',
    },
    width: '0.8rem',
    backgroundColor: '#3F3F3F',
    border: '0.35rem solid #fff',
    borderTop: '0.75rem solid #fff',
    borderBottom: '0.75rem solid #fff',
    borderRadius: '0 0.3rem 0.3rem 0',
    marginLeft: '0.7rem',
  },
  valueLabel: {
    [theme.breakpoints.down('xs')]: {
      top: '-23px',
      padding: '3px 6px',
      fontSize: '0.7rem',
    },
    position: 'absolute',
    top: '-28px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '4px 8px',
    backgroundColor: '#fff',
    color: '#000',
    fontSize: '0.8rem',
    borderRadius: '3px',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
    zIndex: '40',
  },
  valueLabelArrow: {
    [theme.breakpoints.down('xs')]: {
      borderTop: '5px solid #fff',
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderBottom: '4px solid transparent',
      transform: 'translateY(1px)',
      marginBottom: '-10.5px',
    },
    width: '0',
    height: '0',
    borderTop: '7px solid #fff',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid transparent',
    transform: 'translateY(2px)',
    margin: 'auto',
    marginBottom: '-13px',
  },
  sideValueLabel: {
    [theme.breakpoints.down('xs')]: {
      top: '-37px',
    },
    position: 'absolute',
    zIndex: '40',
    top: '-43.5px',
    left: '50%',
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: '300',
    transform: 'translateX(-50%)',
    borderRadius: '3px',
    pointerEvents: 'none',
  },
  sideValueLabelContent: {
    [theme.breakpoints.down('xs')]: {
      height: '1.05rem',
      width: '2.6rem',
    },
    width: '3rem',
    height: '1.25rem',
    color: '#fff',
    position: 'relative',
    fontSize: '0.8rem',
    borderRadius: '3px',
    border: '1px solid #fff',
  },
  sideValueLabelSpan: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    color: '#fff',
    border: 'none',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '42',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap',
    borderRadius: '3px',
    pointerEvents: 'none',
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sideValueLabelArrow: {
    [theme.breakpoints.down('xs')]: {
      bottom: '-3px',
    },
    width: '12px',
    height: '12px',
    border: '1px solid #fff',
    margin: 'auto',
    position: 'absolute',
    zIndex: '41',
    bottom: '-5px',
    left: '50%',
    transform: 'rotateY(45deg) rotateZ(45deg) translate(-50%, 50%)',
    backgroundColor: '#000',
  },
}));

export { useStyles };
