import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/user.actions.js';
import { useTranslation } from 'react-i18next';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import { useDropzone } from 'react-dropzone';
import { List, ListItem, ListItemAvatar, Avatar, IconButton, CircularProgress } from '@material-ui/core';
import { AudiotrackSharp as AudioIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { Collapse } from '@mui/material';

import { dragAndDropStyleNew } from './shared.styles';
import { useStyles } from './TTSTrainVoicePage.styles.js';
import { getFilesDuration, getTTSTrainingDuration, showMessageV2 } from '../../utils/page.utils';
import { statusCheckInterval } from '../../constants/app.constants.js';

import {
  fetchSubscriptionInfo,
  checkTTSTrainingCompletion,
  uploadTTSTraining,
  uploadErrorMessage,
} from '../../services/page.services';
import { checkValidLoginStatus } from '../../utils/user.utils';
import addIcon from '../../img/addIcon.png';

import FreeTrainingMessageModal from '../layout/FreeTrainingMessageModal.js';
import stepChecked from '../../img/step-checked.png';

function TTSTrainVoicePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [label, setLabel] = useState();
  const [duration, setDuration] = useState(0);
  const [trainingRemainingCount, setTrainingRemainingcount] = useState(0);
  const [trainingInProgress, setTrainingInProgress] = useState(false);
  const [openFreeTrainingModal, setOpenFreeTrainingModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  useEffect(() => {
    const durationInMin = Math.ceil(duration);
    const stepOneComplete = files.length > 0 && durationInMin >= trainingDuration.min;
    if (!stepOneComplete && !label) {
      setCurrentStep(Math.max(1, currentStep));
    } else if (stepOneComplete && !label) {
      setCurrentStep(Math.max(2, currentStep));
    } else if (stepOneComplete && label) {
      setCurrentStep(Math.max(3, currentStep));
    }
  }, [files, label, duration]);

  const trainingDuration = getTTSTrainingDuration(user.subscription?.type, user.subscription?.status === 'past_due');
  const durationInMin = Math.ceil(duration);
  const readyForSubmit = label && files.length > 0 && durationInMin >= trainingDuration.min;

  const checkAndPollPendingTraining = async () => {
    let trainings = await checkTTSTrainingCompletion();

    while (_.some(trainings, { status: 'PENDING' })) {
      setTrainingInProgress(true);
      await new Promise(resolve => setTimeout(resolve, statusCheckInterval));
      trainings = await checkTTSTrainingCompletion();
    }
    await checkRemainingCount();
    setTrainingInProgress(false);
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    setTrainingRemainingcount(
      (subscriptionInfo?.type === 'FREE'
        ? subscriptionInfo?.freeTtsTrainingRemaining + subscriptionInfo?.trainingCreditsRemaining
        : subscriptionInfo?.trainingCreditsRemaining) || 0
    );
  };

  const initPage = async (isOnSubmit = false) => {
    try {
      if (!isAdmin && !isCustom) {
        checkAndPollPendingTraining();
      }
      await checkRemainingCount();
    } catch (e) {
      const message = isOnSubmit ? t('modal.pageLoadFailOnSubmit') : t('modal.pageLoadFail');
      showMessageV2(dispatch, message, { reloadOnClose: true });
    }
    setFiles([]);
    setLabel();
    setDuration(0);
  };

  const onDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      let max = await getFilesDuration(files);
      const newFileDuration = await getFilesDuration(acceptedFiles);
      const newMaxDuration = max + newFileDuration;
      if (newMaxDuration > trainingDuration.max) {
        showMessageV2(
          dispatch,
          t('ttsTrainingTab.modal.exceedMaxDuration', {
            maxDuration: trainingDuration.max / 60,
            minDuration: trainingDuration.min,
          })
        );
      } else {
        setDuration(max + newFileDuration);
        setFiles(prevState => [...prevState, ...acceptedFiles]);
      }
    }
  };

  const onDropRejected = async input => {
    if (checkValidLoginStatus(userId, dispatch)) {
      const error = input[0].errors[0];
      if (error.code === 'file-invalid-type') {
        showMessageV2(dispatch, t('trainingTab.modal.notSupportedFileType'));
      }
      if (error.code === 'size-too-large') {
        showMessageV2(dispatch, t('ttsTrainingTab.modal.sizeTooLarge'));
      }
      if (error.code === 'too-many-files') {
        showMessageV2(dispatch, t('ttsTrainingTab.modal.tooManyFiles'));
      }
      if (error.code === 'duplicate-files') {
        showMessageV2(dispatch, t('ttsTrainingTab.modal.duplicateFiles'));
      }
    }
  };
  const fileValidator = file => {
    if (file.size > 10000000) {
      return {
        code: 'size-too-large',
        message: `file is larger than 10MB`,
      };
    }
    if (files.length) {
      for (var uploadedFile of files) {
        if (
          uploadedFile.name === file.name &&
          uploadedFile.size === file.size &&
          uploadedFile.lastModified.toString() === file.lastModified.toString()
        ) {
          return {
            code: 'duplicate-files',
            message: `duplicate files`,
          };
        }
      }
    }

    return null;
  };

  const trainDropzone = useDropzone({
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/mpeg-4': ['.m4a'],
      'audio/flac': ['.flac'],
      'audio/ogg': ['.ogg'],
    },
    onDropAccepted,
    onDropRejected,
    preventDuplicates: true,
    disabled: loading,
    validator: fileValidator,
    maxFiles: 10,
  });

  const handleUpload = async (files, label, subscriptionType) => {
    try {
      setLoading(true);
      const uploadError = await uploadTTSTraining(files, label, subscriptionType);
      if (!!uploadError) {
        if (uploadError === 'pending') {
          showMessageV2(dispatch, t('modal.trainingInProgressError'), { reloadOnClose: true });
        } else if (uploadError === 'noCount') {
          showMessageV2(dispatch, t('modal.noRemainingError'), { reloadOnClose: true });
        } else {
          showMessageV2(dispatch, t('modal.genericError'), { reloadOnClose: true });
        }
      } else {
        await initPage();
      }
    } catch (e) {
      if (e.message) {
        const codeMessage = `code: ${e.code}, message: ${e.message}, config: ${JSON.stringify(e.config)}`;
        await uploadErrorMessage(codeMessage);
      } else {
        await uploadErrorMessage('no message - ' + JSON.stringify(e));
      }
      showMessageV2(dispatch, t('modal.fileUploadFail'));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (checkValidLoginStatus(userId, dispatch)) {
      if (user.subscription?.type === 'FREE' && trainingRemainingCount === 0) {
        setOpenFreeTrainingModal(true);
        return;
      }
      if (!label || files.length === 0 || trainingRemainingCount <= 0 || trainingInProgress) {
        return;
      }
      const durationInMin = Math.ceil(duration);
      if (durationInMin < trainingDuration.min) {
        showMessageV2(
          dispatch,
          t('ttsTrainingTab.modal.minDuration', {
            minDuration: trainingDuration.min,
          })
        );
        return;
      }
      await handleUpload(files, label, user.subscription.type);
    }
  };

  const classes = useStyles();
  return (
    <>
      <FreeTrainingMessageModal open={openFreeTrainingModal} onClose={() => setOpenFreeTrainingModal(false)} />
      <div className={classes.container}>
        <div className={classes.pageTitle}>{t('ttsTrainingTab.title')}</div>
        <div className={classes.stepHeader}>
          {files.length > 0 && durationInMin >= trainingDuration.min ? (
            <img className={classes.stepHeaderChecked} src={stepChecked} />
          ) : (
            <span className={classes.stepHeaderNum}>1</span>
          )}
          <span>{t('trainingTab.stepOne.title')}</span>

          <HtmlTooltip
            title={
              <div className={'globalTooltipContainer'}>
                <div className={'globalTooltipHeader'}>{t('trainingTab.stepOne.tooltip.titleOne')}</div>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>1.</div>
                  <div className={'globalTooltipText'}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.0')}</div>
                </div>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>2.</div>
                  <div className={'globalTooltipText'}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.1')}</div>
                </div>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>3.</div>
                  <div className={'globalTooltipText'}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.2')}</div>
                </div>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>4.</div>
                  <div className={'globalTooltipText'}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.3')}</div>
                </div>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>5.</div>
                  <div className={'globalTooltipText'}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.4')}</div>
                </div>
              </div>
            }
          >
            <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
          </HtmlTooltip>
        </div>
        <div className={classes.section}>
          <div {...trainDropzone.getRootProps({ style: dragAndDropStyleNew })}>
            <input {...trainDropzone.getInputProps()} />
            <div className={classes.dragAndDrop}>
              <img src={addIcon} className={classes.addButtonImg} />
              <div>
                <div className={classes.dragAndDropText}>{t('trainingTab.stepOne.dragAndDropText')}</div>
                <div className={classes.dragAndDropText}>{t('ttsTrainingTab.stepOne.sizeLimitText')}</div>
                <div className={classes.dragAndDropText}>
                  {t('ttsTrainingTab.stepOne.dragAndDropDuration', {
                    maxDuration: trainingDuration.max / 60,
                    minDuration: trainingDuration.min,
                  }) +
                    ', ' +
                    t('ttsTrainingTab.stepOne.numberLimitText')}
                </div>
              </div>
            </div>
          </div>

          <div>
            <List className={classes.dragAndDropList}>
              {files.map((file, index) => (
                <ListItem key={index} className={classes.listItem}>
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar className={classes.avatar}>
                      <AudioIcon className={classes.audioIcon} />
                    </Avatar>
                  </ListItemAvatar>
                  <div className={classes.listItemText}>p{file.name}</div>
                  <IconButton
                    onClick={async () => {
                      const newFileList = files.filter((_file, i) => i !== index);
                      setFiles(() => newFileList);
                      setDuration(await getFilesDuration(newFileList));
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon style={{ color: '#fff', width: '20px' }} />
                  </IconButton>
                </ListItem>
              ))}
              <ListItem key={files.length} className={classes.durationText} disableGutters>
                {t('trainingTab.stepOne.durationText')} : {Math.floor(duration / 60)}{' '}
                {t('trainingTab.stepOne.durationUnit')} {Math.ceil(duration % 60)} {t('ttsTrainingTab.stepOne.second')}
              </ListItem>
            </List>
          </div>
        </div>
        <div
          className={`${classes.stepHeader} ${currentStep < 2 && classes.noMargin} ${currentStep < 2 && classes.greyout}`}
        >
          {label ? (
            <img className={classes.stepHeaderChecked} src={stepChecked} />
          ) : (
            <span className={classes.stepHeaderNum}>2</span>
          )}
          <span>{t('trainingTab.stepTwo.title')}</span>
        </div>
        <Collapse in={currentStep >= 2} timeout="auto" unmountOnExit>
          <div className={classes.section}>
            <input
              onChange={e => {
                if (checkValidLoginStatus(userId, dispatch)) {
                  setLabel(e.target.value);
                }
              }}
              placeholder={t('trainingTab.stepTwo.placeholder')}
              className={classes.labelInput}
              disabled={loading}
            />
          </div>
        </Collapse>
        <div className={classes.separater} />

        <div className={classes.buttonContainer}>
          {!loading && (
            <>
              <div />
              <div className={classes.submitContainer}>
                <HtmlTooltip
                  title={
                    <div className={'globalTooltipContainer'}>
                      <div className={'globalTooltipTextContainer'}>
                        <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                        <div className={'globalTooltipText'}>{t('ttsTrainingTab.submit.tooltip.contents.0')}</div>
                      </div>
                      <div className={'globalTooltipTextContainer'}>
                        <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                        <div className={'globalTooltipText'}>{t('ttsTrainingTab.submit.tooltip.contents.1')}</div>
                      </div>
                      <div className={'globalTooltipTextContainer'}>
                        <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                        <div className={'globalTooltipText'}>{t('ttsTrainingTab.submit.tooltip.contents.2')}</div>
                      </div>
                    </div>
                  }
                >
                  <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                </HtmlTooltip>

                {trainingInProgress ? (
                  <div className={classes.progressButton}>
                    <>
                      <div>{t('trainingTab.submit.inProgress')}</div>
                      <CircularProgress className={classes.loadingCircle} />
                    </>
                  </div>
                ) : (
                  <div
                    className={`${classes.button} ${!readyForSubmit && classes.disabledButton}`}
                    onClick={handleSubmit}
                  >
                    {userId && user.subscription?.type !== 'FREE' && trainingRemainingCount <= 0
                      ? t('trainingTab.submit.noRemaining')
                      : t('trainingTab.submit.start')}
                  </div>
                )}
              </div>
            </>
          )}
          {loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress size="1.4rem" />
              <span className={classes.loadingText}>{t('trainingTab.submit.uploading')}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TTSTrainVoicePage;
