const subscriptionItems = (t, currency, planType = 'monthly') => {
  const items = t('subscriptionItems', { returnObjects: true });
  return items.map(elem => {
    const model = planType === 'yearly' && elem.yearly ? elem.yearly.model : elem.model;
    return {
      name: elem.name,
      model,
      price: elem.monthlyRate[currency],
      yearlyPrice: elem.yearly ? elem.yearly.monthlyRate[currency] : null,
      features: elem.features,
    };
  });
};
export default subscriptionItems;
