import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '3px',
    height: '35px',
    width: '140px',
    margin: '0 8px 0 0',
    color: '#ffffff',
    backgroundColor: '#e74c3c',
    border: '1px solid #e74c3c',
    '&:hover': {
      backgroundColor: '#c0392b',
      border: '1px solid #c0392b',
    },
  },
  deleteButtonDisabled: {
    cursor: 'not-allowed',
    color: '#ffffff',
    opacity: 0.5,
  },
  noButton: {
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '3px',
    borderRadius: '3px',
    color: '#c4c4c4',
    height: '35px',
    width: '140px',
    margin: '0 0 0 8px',
    border: '1px solid #a4a4a4',
    '&:hover': {
      background: '#d1fe75',
      color: '#1e1e1e',
      border: '1px solid #d1fe75',
    },
  },
  buttonContainer: {
    display: 'flex',
  },
  message: {
    fontSize: '16px',
    marginTop: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '295px',
    wordBreak: 'auto-phrase',
    lineHeight: '22px',
  },
  subMessage: {
    fontSize: '14px',
    marginBottom: '10px',
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '295px',
    wordBreak: 'auto-phrase',
    lineHeight: '22px',
  },
  labelInput: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
    },
    fontSize: '16px !important',
    padding: '0 8px',
    width: '80%',
    height: '36px',
    color: '#9a9a9a',
    padding: '18px 10px',
    backgroundColor: '#292929',
    border: '1px solid #9A9A9A',
    borderRadius: '8px !important',
    marginBottom: '16px',
    '&:focus': {
      outline: 'none',
    },
  },
}));

export { useStyles };
