import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './DeleteAccountConfirmationModal.styles.js';
import BaseModalLayout from './BaseModalLayout.js';

export default function DeleteAccountConfirmationModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [activateButton, setActivateButton] = useState(false);

  return (
    <BaseModalLayout {...props} open sizeMax>
      <>
        <div className={classes.message}>{t('contactTab.message.deleteAccount')}</div>
        <div className={classes.subMessage}>{t('contactTab.message.confirmDeleteAccount')}</div>
        <input
          onChange={e => {
            if (e.target.value === 'DELETE') {
              setActivateButton(true);
            } else {
              setActivateButton(false);
            }
          }}
          placeholder={'DELETE'}
          className={classes.labelInput}
        />
        <div className={classes.buttonContainer}>
          <div
            className={`${!activateButton && classes.deleteButtonDisabled} ${classes.deleteButton}`}
            onClick={() => activateButton && props.onConfirmation(true)}
          >
            {t('contactTab.message.deleteConfirm')}
          </div>
          <div className={`${classes.noButton}`} onClick={() => props.onConfirmation(false)}>
            {t('modal.confirmationNo')}
          </div>
        </div>
      </>
    </BaseModalLayout>
  );
}
