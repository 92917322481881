import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, FormControlLabel, Button, ButtonGroup } from '@material-ui/core';
import { useStyles, SettingsSwitch } from './FaceSwapSettings.styles';
import Slider from '@mui/material/Slider';
import Dialog from '@material-ui/core/Dialog';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';

function FaceSwapSettings(props) {
  const {
    t,
    isSharpnessOn,
    blendRatio,
    isWatermarkOn,
    handleWatermarkToggle,
    handleStart,
    tierToShowOutputFormat,
    user,
    navigate,
    coinCreditLeft,
    requiredCoins,
    onApply,
    duration,
    faceCount,
    previewLoading,
    isDisabled,
  } = props;

  const classes = useStyles();

  // Local temporary state
  const [localIsSharpnessOn, setLocalIsSharpnessOn] = useState(isSharpnessOn);
  const [localBlendRatio, setLocalBlendRatio] = useState(blendRatio);
  const [showSharpnessDialog, setShowSharpnessDialog] = useState(false);
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState(30);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const [videoBoxLoading, setVideoBoxLoading] = useState(true);

  const blendRatioMarks = [
    {
      value: 30,
      label: t('faceSwapTab.stepTwo.settings.blednRatioMarks.0'),
    },
    {
      value: 50,
      label: t('faceSwapTab.stepTwo.settings.blednRatioMarks.1'),
    },
    {
      value: 70,
      label: t('faceSwapTab.stepTwo.settings.blednRatioMarks.2'),
    },
    {
      value: 90,
      label: t('faceSwapTab.stepTwo.settings.blednRatioMarks.3'),
    },
  ];
  const [currentTooltipVideo, setCurrentTooltipVideo] = useState(
    'https://image.sorisori.ai/faceswap-sharpness-tooltip-verylow.mp4'
  );
  const videoMap = {
    30: 'https://image.sorisori.ai/faceswap-sharpness-tooltip-verylow.mp4',
    50: 'https://image.sorisori.ai/faceswap-sharpness-tooltip-low.mp4',
    70: 'https://image.sorisori.ai/faceswap-sharpness-tooltip-high.mp4',
    90: 'https://image.sorisori.ai/faceswap-sharpness-tooltip-veryhigh.mp4',
  };

  useEffect(() => {
    if (isDisabled && localIsSharpnessOn) {
      setShowSharpnessDialog(true);
      setLocalIsSharpnessOn(false);
    }
  }, [localIsSharpnessOn]);

  // Sync local state with props when props change
  useEffect(() => {
    setLocalIsSharpnessOn(isSharpnessOn);
    setLocalBlendRatio(blendRatio);
  }, [isSharpnessOn, blendRatio]);

  // Handlers to update local state
  const handleLocalSharpnessToggle = () => {
    setLocalIsSharpnessOn(prev => !prev);
  };

  const handleLocalBlendRatioChange = (event, newValue) => {
    setLocalBlendRatio(newValue);
  };

  const currentMark = blendRatioMarks.find(mark => mark.value === localBlendRatio);

  // Handler for Apply button
  const handleApplyClick = () => {
    onApply({
      isSharpnessOn: localIsSharpnessOn,
      blendRatio: localBlendRatio,
    });
  };

  const handleDialogClose = () => {
    setShowSharpnessDialog(false);
    setLocalIsSharpnessOn(false);
  };

  const handleTooltipOpen = e => {
    e.stopPropagation();
    setTooltipOpen(true);
  };

  return (
    <>
      <div onClick={() => setTooltipOpen(false)} onMouseEnter={() => setTooltipOpen(false)}>
        <Typography variant="h6" className={classes.settingsTitle}>
          {t('faceSwapTab.stepTwo.settings.title')}
        </Typography>
        <div className={classes.settingsSeparater} />

        <Box className={classes.settingsInnerBoxOne}>
          {/* Sharpness Toggle */}
          <Box className={classes.settingsInnerBoxSub} mb={2}>
            <div className={classes.settingsTextContainer}>
              <div className={classes.settingsText}>{t('faceSwapTab.stepTwo.settings.sharpness')}</div>
              <HtmlTooltip
                title={
                  <div className={'globalTooltipContainer'}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('faceSwapTab.tooltip.sharpness.1')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('faceSwapTab.tooltip.sharpness.2')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('faceSwapTab.tooltip.sharpness.3')}</div>
                    </div>
                  </div>
                }
              >
                <img style={{ marginTop: '-1px', marginBottom: '1.5px' }} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
            </div>
            <FormControlLabel
              control={
                <SettingsSwitch defaultChecked checked={localIsSharpnessOn} onChange={handleLocalSharpnessToggle} />
              }
            />
          </Box>
          <div className={classes.settingsSeparaterSub} />

          {/* Original-Swapped Image Blend Ratio */}
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            mb={2}
            alignItems="center"
            style={{ opacity: localIsSharpnessOn ? 1 : 0.5 }}
          >
            <Box className={classes.settingsInnerBoxSub}>
              <div className={classes.settingsTextContainer}>
                <div div className={classes.settingsText}>
                  {t('faceSwapTab.stepTwo.settings.blendRatio')}
                </div>
                <HtmlTooltip
                  interactive
                  open={tooltipOpen}
                  className={classes.tooltipRoot}
                  title={
                    <div
                      className={'globalTooltipContainer'}
                      onClick={() => setTooltipOpen(false)}
                      onMouseLeave={() => setTooltipOpen(false)}
                    >
                      <div className={classes.tooltipVideoContainer}>
                        <div className={classes.tooltipVideoWrapper}>
                          {videoLoading && (
                            <div className={classes.tooltipVideoBox}>
                              <CircularProgress className={classes.circularLoading} />
                            </div>
                          )}
                          <video
                            id="original-video"
                            src="https://image.sorisori.ai/faceswap-sharpness-tooltip-original.mp4"
                            autoPlay
                            loop
                            muted
                            preload="auto"
                            controls={false}
                            className={classes.tooltipVideoBox}
                            onLoadedData={() => setVideoLoading(false)}
                            style={{ display: videoLoading && 'none' }}
                          ></video>
                          <div className={classes.tooltipButtonText}>
                            {t('faceSwapTab.stepTwo.settings.originalVideo')}
                          </div>
                        </div>
                        <ArrowForwardIosIcon className={classes.tooltipArrow} />
                        <div className={classes.tooltipVideoWrapper}>
                          <div className={classes.tooltipVideoBox}>
                            {videoLoading && (
                              <div className={classes.tooltipVideoBox}>
                                <CircularProgress className={classes.circularLoading} />
                              </div>
                            )}
                            {Object.entries(videoMap).map(([value, src]) => (
                              <video
                                key={value}
                                id={`blend-video-${value}`}
                                src={src}
                                autoPlay
                                loop
                                muted
                                preload="auto"
                                className={classes.tooltipVideo}
                                style={{
                                  opacity: hoveredButtonIndex === Number(value) ? 1 : 0,
                                  transition: 'opacity 0.3s ease-in-out',
                                }}
                              ></video>
                            ))}
                          </div>
                          <ButtonGroup variant="contained" aria-label="Basic button group">
                            {blendRatioMarks.map((mark, i) => (
                              <Button
                                key={i}
                                onMouseEnter={() => setHoveredButtonIndex(mark.value)}
                                onMouseLeave={() => setHoveredButtonIndex(30)}
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setHoveredButtonIndex(mark.value);
                                }}
                                onTouchStart={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setHoveredButtonIndex(mark.value);
                                }}
                                onMouseDown={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setHoveredButtonIndex(mark.value);
                                }}
                                className={classes.tooltipButton}
                                style={
                                  hoveredButtonIndex === mark.value ? { color: '#000', backgroundColor: '#fff' } : {}
                                }
                              >
                                {mark.label}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <img
                    onClick={handleTooltipOpen}
                    onMouseEnter={handleTooltipOpen}
                    style={{ marginTop: '-1px', marginBottom: '1.5px' }}
                    src={tooltipImg}
                    alt="tooltip-img"
                  />
                </HtmlTooltip>
              </div>
              <div className={classes.settingsRatio}>{currentMark ? currentMark.label : ''}</div>
            </Box>
            <Slider
              aria-label="Restricted values"
              value={localBlendRatio}
              onChange={handleLocalBlendRatioChange}
              step={20}
              min={30}
              max={90}
              valueLabelDisplay="off"
              marks
              disabled={!localIsSharpnessOn}
              classes={{ root: classes.settingsRoot }}
            />
          </Box>
          <Button variant="contained" onClick={handleApplyClick} className={classes.applyButton}>
            {t('faceSwapTab.button.apply')}
          </Button>
        </Box>

        {/* Watermark Toggle */}
        <Box mb={2} mt={1} className={classes.settingsInnerBoxTwo}>
          <div className={classes.settingsText}>{t('faceSwapTab.stepTwo.settings.watermark')}</div>
          <FormControlLabel
            control={<SettingsSwitch defaultChecked checked={isWatermarkOn} onChange={handleWatermarkToggle} />}
          />
        </Box>

        {/* Coin */}
        {coinCreditLeft < requiredCoins ? (
          <div className={classes.redCoin}>
            {t('faceSwapTab.stepTwo.settings.leftCoin.0')}
            {coinCreditLeft}
            {t('faceSwapTab.stepTwo.settings.leftCoin.1')}
          </div>
        ) : (
          <div className={classes.coin}>
            {t('faceSwapTab.stepTwo.settings.leftCoin.0')}
            {coinCreditLeft}
            {t('faceSwapTab.stepTwo.settings.leftCoin.1')}
          </div>
        )}
        <div className={classes.coin}>
          {requiredCoins}
          {t('faceSwapTab.stepTwo.settings.requiredCoin')}
          <HtmlTooltip
            title={
              <div className={'globalTooltipContainer'}>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                  <div className={'globalTooltipText'}>{t('faceSwapTab.tooltip.coinLogic.0')}</div>
                </div>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                  <div className={'globalTooltipText'}>{t('faceSwapTab.tooltip.coinLogic.1')}</div>
                </div>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                  <div className={'globalTooltipText'}>{t('faceSwapTab.tooltip.coinLogic.2')}</div>
                </div>
                <div className={'globalTooltipTextContainer'}>
                  <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                  <div className={'globalTooltipText'}>
                    {t('faceSwapTab.tooltip.coinCal.0')}
                    <strong>{duration}</strong>
                    {t('faceSwapTab.tooltip.coinCal.1')}
                    <strong>{Math.ceil(duration / 15) * 15}</strong>
                    {t('faceSwapTab.tooltip.coinCal.2')}
                    <strong>{faceCount}</strong>
                    {t('faceSwapTab.tooltip.coinCal.3')}
                    {requiredCoins}
                    {t('faceSwapTab.tooltip.coinCal.4')}
                  </div>
                </div>
              </div>
            }
          >
            <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
          </HtmlTooltip>
        </div>

        <Box mt={2} className={classes.settingsBtnContainer}>
          {/* Start Button */}
          <Box>
            <Button variant="contained" onClick={handleStart} className={classes.startButton}>
              {t('faceSwapTab.button.start')}
            </Button>
          </Box>
          <Button variant="contained" onClick={handleApplyClick} className={classes.mobileApplyButton}>
            {t('faceSwapTab.button.apply')}
          </Button>
          {!tierToShowOutputFormat.includes(user.subscription?.type) && (
            <Button className={classes.upgradeButton} onClick={() => navigate('/subscription')}>
              {t('mixingModal.upgradeButton.0')}
              <strong style={{ marginLeft: '0.2rem' }}>{t('mixingModal.upgradeButton.1')}</strong>
            </Button>
          )}
        </Box>
      </div>
      <Dialog open={showSharpnessDialog} onClose={handleDialogClose} className={classes.upgradeDialog}>
        <div className={classes.dialogTitle}>{t('mixingModal.dialog.title')}</div>
        <div className={classes.dialogText}>{t('faceSwapTab.dialog.sharpnessOn')}</div>
        <div className={classes.dialogButton} onClick={() => navigate('/subscription')}>
          {t('mixingModal.upgradeButton.0')}
          <strong>{t('mixingModal.upgradeButton.1')}</strong>
        </div>
      </Dialog>
      <Dialog open={tooltipOpen} onClose={() => setTooltipOpen(false)} className={classes.upgradeDialogTwo}>
        <div
          className={'globalTooltipContainer'}
          onClick={() => setTooltipOpen(false)}
          onMouseLeave={() => setTooltipOpen(false)}
        >
          <div className={classes.dialogTitle}>{t('화질개선 설정 비교')}</div>
          <div className={classes.tooltipVideoContainer}>
            <div className={classes.tooltipVideoWrapper}>
              {videoLoading && (
                <div className={classes.tooltipVideoBox}>
                  <CircularProgress className={classes.circularLoading} />
                </div>
              )}
              <video
                id="original-video"
                src="https://image.sorisori.ai/faceswap-sharpness-tooltip-original.mp4"
                autoPlay
                loop
                muted
                preload="auto"
                controls={false}
                className={classes.tooltipVideoBox}
                onLoadedData={() => setVideoLoading(false)}
                style={{ display: videoLoading && 'none' }}
              ></video>
              <div className={classes.tooltipButtonText}>{t('faceSwapTab.stepTwo.settings.originalVideo')}</div>
            </div>
            <ArrowForwardIosIcon className={classes.tooltipArrow} />
            <div className={classes.tooltipVideoWrapper}>
              {videoBoxLoading && (
                <div className={classes.tooltipVideoBox}>
                  <CircularProgress className={classes.circularLoading} />
                </div>
              )}
              <div className={classes.tooltipVideoBox}>
                {Object.entries(videoMap).map(([value, src]) => (
                  <video
                    key={value}
                    id={`blend-video-${value}`}
                    src={src}
                    autoPlay
                    loop
                    muted
                    preload="auto"
                    className={classes.tooltipVideo}
                    onLoadedData={() => setVideoBoxLoading(false)}
                    style={{
                      position: 'absolute',
                      zIndex: value,
                      opacity: hoveredButtonIndex === Number(value) ? 1 : 0,
                      transition: 'opacity 0.3s ease-in-out',
                      display: hoveredButtonIndex !== Number(value) && videoBoxLoading && 'none',
                    }}
                  ></video>
                ))}
              </div>
              <ButtonGroup variant="contained" aria-label="Basic button group">
                {blendRatioMarks.map((mark, i) => (
                  <Button
                    key={i}
                    onMouseEnter={() => setHoveredButtonIndex(mark.value)}
                    onMouseLeave={() => setHoveredButtonIndex(30)}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHoveredButtonIndex(mark.value);
                    }}
                    onTouchStart={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHoveredButtonIndex(mark.value);
                    }}
                    onMouseDown={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setHoveredButtonIndex(mark.value);
                    }}
                    className={classes.tooltipButton}
                    style={hoveredButtonIndex === mark.value ? { color: '#000', backgroundColor: '#fff' } : {}}
                  >
                    {mark.label}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default FaceSwapSettings;
