import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

import ConfirmationModal from '../layout/ConfirmationModal';
import PaymentHistoryModal from '../layout/PaymentHistoryModal';
import PaymentOptionModal from '../layout/PaymentOptionModal';

import kakaoLogo from '../../img/kakaologo.svg';
import googleLogo from '../../img/googlelogo.svg';
import tossPayWhiteLogo from '../../img/tossPayWhite.svg';
import creditCardImg from '../../img/creditCard.svg';
import kakaoPayLogo from '../../img/kakaoPay.png';

import playStoreLogo from '../../img/playstore.png';
import appStoreLogo from '../../img/appstore.png';

import { PaymentService, SubscriptionType } from '../../constants/app.constants.js';

import { userActions } from '../../actions/user.actions';

import { cardTypeToImg, showMessageV2 } from '../../utils/page.utils.js';

import * as pageService from '../../services/page.services';
import { getUserProfile } from '../../services/page.services';
import * as PortOne from '@portone/browser-sdk/v2';

import { useStyles } from './ProfilePage.styles';
const portOneStoreId = 'store-94887967-6f19-478d-b8dd-5320faafae96';
const portOneChannelKey = 'channel-key-8ff686d9-d11e-402d-95f9-67a5e848d7bf'; // 자동결제

const ProfilePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [confirmationModalProps, setConfirmationModalProps] = useState({});
  // const [paymentHistoryProps, setPaymentHistoryProps] = useState({});
  const [loadingCancelSubscriptionChange, setLoadingCancelSubscriptionChange] = useState(false);

  const text = t('profileTab', { returnObjects: true });

  const user = useSelector(state => state.user);
  const userId = user?.id;
  const subscriptionType = user?.subscription?.type;

  useEffect(() => {
    let timeoutId;
    if (userId) {
      initPage();
      timeoutId = setTimeout(initPage, 4000);
    }
    return () => clearTimeout(timeoutId);
  }, [userId]);

  const initPage = async () => {
    try {
      const {
        paymentInfo,
        nextPeriodStart,
        subscriptionType,
        startDate,
        endDate,
        creditResetDate,
        nextSubscriptionType,
        paymentService,
      } = await getUserProfile();

      setSubscriptionInfo({
        ...subscriptionInfo,
        paymentInfo,
        paymentService,
        startDate: moment(startDate).format('MM/DD/YYYY'),
        endDate: moment(endDate).format('MM/DD/YYYY'),
        creditResetDate: creditResetDate ? moment(creditResetDate).format('MM/DD/YYYY') : null,
        nextSubscriptionType: nextSubscriptionType || SubscriptionType.FREE,
        nextSubscriptionTypeText: nextSubscriptionType
          ? text.subscriptionType[nextSubscriptionType]
          : text.subscriptionType[SubscriptionType.FREE],
        subscriptionTypeText: subscriptionType ? text.subscriptionType[subscriptionType] : '',
        nextPeriodStart: nextPeriodStart && moment.unix(nextPeriodStart).format('MM/DD/YYYY'),
        isMobileSubscription: paymentService !== PaymentService.STRIPE && paymentService !== PaymentService.TOSS,
      });
    } catch (e) {
      showMessageV2(dispatch, t('pageLoadFailed'));
    }
  };

  // const handlePaymentHistory = async () => {
  //   try {
  //     const history = await pageService.getPaymentHistory();
  //     setPaymentHistoryProps({
  //       open: true,
  //       payments: history,
  //       onClose: () => setPaymentHistoryProps({ open: false }),
  //     });
  //   } catch (e) {
  //     showMessageV2(dispatch, t('requestFailed'));
  //   }
  // };

  const cancelSubscription = async () => {
    setConfirmationModalProps({
      open: true,
      message: t('subscriptionTab.cancel.message'),
      onConfirmation: async answer => {
        if (answer) {
          setConfirmationModalProps({ open: true, loading: true });
          try {
            await pageService.cancelSubscription();
            showMessageV2(dispatch, t('subscriptionTab.cancel.success'));
            setSubscriptionInfo({});
            initPage();
          } catch (e) {
            showMessageV2(dispatch, t('subscriptionTab.cancel.fail'));
          }
        }
        setConfirmationModalProps({ open: false });
      },
      onClose: () => setConfirmationModalProps({ open: false }),
    });
  };

  const cancelSubscriptionChange = async () => {
    setConfirmationModalProps({
      open: true,
      message: t('subscriptionTab.cancelChange.message'),
      onConfirmation: async answer => {
        if (answer) {
          setLoadingCancelSubscriptionChange(true);
          setConfirmationModalProps({ open: true, loading: true });
          try {
            await pageService.cancelSubscriptionChange();
            showMessageV2(dispatch, t('subscriptionTab.cancelChange.success'));
            setSubscriptionInfo({});
            initPage();
          } catch (e) {
            showMessageV2(dispatch, t('subscriptionTab.cancelChange.fail'));
          }
          setLoadingCancelSubscriptionChange(false);
        }
        setConfirmationModalProps({ open: false });
      },
      onClose: () => setConfirmationModalProps({ open: false }),
    });
  };

  const handleChangePayment = async () => {
    try {
      const order = await pageService.createOrder({ changeMethod: true });
      await pageService.triggerChangePaymentSession(order.id);
    } catch (e) {
      showMessageV2(dispatch, t('requestFailed'));
    }
  };

  // const cardPlaceholder = `${'\u2022'}${'\u2022'}${'\u2022'}${'\u2022'} ${'\u2022'}${'\u2022'}${'\u2022'}${'\u2022'} ${'\u2022'}${'\u2022'}${'\u2022'}${'\u2022'}`;
  const cardPlaceholder = '•••• •••• ••••';
  const cardImgSrc = cardTypeToImg[subscriptionInfo.paymentInfo?.cardType] || creditCardImg;

  const renderCurrentCycle = () => {
    if (!subscriptionType || subscriptionType === SubscriptionType.FREE || !subscriptionInfo?.startDate) {
      return null;
    }
    return (
      <div className={classes.contentElem}>
        {`${text.sections.payment.currentCycle}: ${subscriptionInfo.startDate} ~ ${subscriptionInfo.endDate}`}
      </div>
    );
  };

  // const renderPaymentHistory = () => {
  //   if (!subscriptionInfo?.subscriptionTypeText || subscriptionInfo?.isMobileSubscription) return null;
  //   return (
  //     <div className={classes.contentLink} onClick={handlePaymentHistory}>
  //       {text.sections.payment.history}
  //     </div>
  //   );
  // };

  const renderCancelSubscriptionChange = () => {
    const { subscriptionTypeText, nextSubscriptionTypeText, isMobileSubscription } = subscriptionInfo;
    if (
      !subscriptionType ||
      subscriptionType === SubscriptionType.FREE ||
      subscriptionTypeText === nextSubscriptionTypeText ||
      isMobileSubscription
    )
      return null;
    return (
      <div className={classes.contentLink} onClick={cancelSubscriptionChange}>
        {text.sections.payment.cancelSubscriptionChange}
      </div>
    );
  };

  const renderCancel = () => {
    if (
      !subscriptionType ||
      subscriptionType === SubscriptionType.FREE ||
      subscriptionInfo?.nextSubscriptionType === SubscriptionType.FREE ||
      subscriptionInfo?.isMobileSubscription
    )
      return null;
    return (
      <div className={classes.contentLink} onClick={cancelSubscription}>
        {text.sections.payment.cancel}
      </div>
    );
  };

  const renderChangePaymentMethod = () => {
    if (
      !subscriptionType ||
      subscriptionType === SubscriptionType.FREE ||
      !subscriptionInfo?.paymentService ||
      subscriptionInfo?.isMobileSubscription
    ) {
      return null;
    }
    return (
      <div className={classes.contentLink} onClick={handleChangePayment}>
        {text.sections.payment.changePayment}
      </div>
    );
  };

  const renderPaymentMethod = () => {
    if (!subscriptionType || subscriptionType === SubscriptionType.FREE) {
      return null;
    }
    return (
      <>
        {subscriptionInfo.paymentService === PaymentService.STRIPE && (
          <>
            {subscriptionInfo.paymentInfo?.type === 'kakao_pay' ? (
              <div className={classes.elemBig}>
                <img className={classes.kakaoPayImg} src={kakaoPayLogo} />
              </div>
            ) : (
              <div className={classes.elemBig}>
                <img
                  className={`${subscriptionInfo.paymentInfo?.cardType === 'visa' ? classes.cardImgVisa : classes.cardImg}`}
                  src={cardImgSrc}
                />

                {subscriptionInfo.paymentInfo?.last4Digit && (
                  <>{`${cardPlaceholder} ${subscriptionInfo.paymentInfo?.last4Digit}`}</>
                )}
              </div>
            )}
          </>
        )}
        {subscriptionInfo.paymentService === PaymentService.ANDROID && (
          <div className={classes.elemBig}>
            <img className={classes.playStoreImg} src={playStoreLogo} />
          </div>
        )}
        {subscriptionInfo.paymentService == PaymentService.IOS && (
          <div className={classes.elemBig}>
            <img className={classes.playStoreImg} src={appStoreLogo} />
          </div>
        )}
        {subscriptionInfo.paymentService === PaymentService.TOSS && (
          <div className={classes.elemBig}>
            <img className={classes.tossImg} src={tossPayWhiteLogo} />
          </div>
        )}
      </>
    );
  };

  const loginLogoSrc = !user?.provider ? null : user.provider === 'google' ? googleLogo : kakaoLogo;
  return (
    <>
      {/* <PaymentHistoryModal {...paymentHistoryProps} /> */}
      <ConfirmationModal {...confirmationModalProps} />

      <div className={classes.pageTitle}>{text.title}</div>
      <div className={classes.separater} />

      {user?.name && (
        <Grid container>
          <Grid container xs={12} sm={12} md={12} className={classes.section}>
            <div className={classes.sectionTitle}>{text.sections.login.title}:</div>
            <div className={classes.sectionContent}>
              <Grid container xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={7} md={6} className={classes.content}>
                  <span className={classes.contentElem}>
                    {loginLogoSrc && <img src={loginLogoSrc} className={classes.loginLogo} />}
                    {user.email}
                  </span>
                  <div className={classes.contentElem}>{user.name}</div>
                </Grid>
                <Grid item xs={12} sm={5} md={6} className={classes.action}>
                  <div
                    className={classes.contentLink}
                    onClick={() => {
                      navigate('/');
                      dispatch(userActions.logout());
                    }}
                  >
                    {text.sections.login.logout}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.separater} />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid container xs={12} sm={12} md={12}>
            <div className={classes.sectionTitle}>{text.sections.payment.title}:</div>
            <div className={classes.sectionContent}>
              <Grid container xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={7} md={6} className={classes.content}>
                  {renderPaymentMethod()}
                  <div className={classes.contentElem}>
                    <div>{text.sections.payment.currentSubscription}</div>
                    <div className={classes.alignCenter}>:</div>
                    {!subscriptionInfo?.subscriptionTypeText && <CircularProgress className={classes.loading} />}
                    {subscriptionInfo?.subscriptionTypeText && (
                      <div
                        onClick={() => navigate('/subscription')}
                        className={`${classes.subscriptionTypeText} ${classes.alignCenter}`}
                      >
                        {subscriptionInfo.subscriptionTypeText}
                      </div>
                    )}
                  </div>
                  {subscriptionInfo.nextPeriodStart && !subscriptionInfo.isMobileSubscription && (
                    <div className={classes.contentElem}>
                      {`${text.sections.payment.nextPaymentDate}: ${subscriptionInfo.nextPeriodStart}`}
                    </div>
                  )}
                  {renderCurrentCycle()}
                  {!!subscriptionInfo.creditResetDate && (
                    <div className={classes.contentElem}>
                      {`${text.sections.payment.nextCreditResetDate}: ${subscriptionInfo.creditResetDate}`}
                    </div>
                  )}
                  {subscriptionType !== SubscriptionType.FREE && !subscriptionInfo.isMobileSubscription && (
                    <div className={classes.contentElem}>
                      {`${text.sections.payment.nextSubscriptionType}:`}
                      <div
                        onClick={() => navigate('/subscription')}
                        className={`${classes.subscriptionTypeText} ${classes.alignCenter}`}
                      >
                        {subscriptionInfo.nextSubscriptionTypeText}
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={5} md={6} className={classes.action}>
                  {renderChangePaymentMethod()}
                  {loadingCancelSubscriptionChange && (
                    <CircularProgress className={classes.loadingCancelSubscriptionChange} />
                  )}
                  {subscriptionInfo &&
                    subscriptionInfo.subscriptionType !== 'FREE' &&
                    subscriptionInfo.subscriptionTypeText !== subscriptionInfo.nextSubscriptionTypeText &&
                    renderCancelSubscriptionChange()}
                  {!subscriptionInfo?.isMobileSubscription && (
                    <div className={classes.contentLink} onClick={() => navigate('/subscription')}>
                      {text.sections.payment.updateSubscription}
                    </div>
                  )}
                  {/* {renderPaymentHistory()} */}
                  {renderCancel()}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={classes.separater} />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid container xs={12} sm={12} md={12}>
            <div className={classes.sectionTitle}>{text.sections.question.title}:</div>
            <div className={classes.sectionContent}>
              <Grid className={classes.contentContact}>
                <span>
                  {text.sections.question.contentPre}
                  <span className={classes.contactLink} onClick={() => navigate('/contact')}>
                    {text.sections.question.contentLink}
                  </span>
                  {text.sections.question.contentPost}
                </span>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProfilePage;
