import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useStyles } from './ContactPage.style';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import { TextField } from '@material-ui/core';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

import { checkValidLoginStatus } from '../../utils/user.utils';
import { uploadContact } from '../../services/page.services';
import { showMessageV2 } from '../../utils/page.utils.js';
import DeleteAccountConfirmationModal from '../layout/DeleteAccountConfirmationModal.js';
import { userActions } from '../../actions/user.actions';

function ContactPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectType, setSelectType] = useState();
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDeleteAccountConfirmationModal, setOpenDeleteAccountConfirmationModal] = useState(false);

  const ContactTypes = {
    FUNCTIONALITY: 'FUNCTIONALITY',
    API_INQUIRY: 'API_INQUIRY',
    REFUND: 'REFUND',
    DELETE_ACCOUNT: 'DELETE_ACCOUNT',
    OTHER: 'OTHER',
  };

  const contactTypesElem = [
    {
      type: ContactTypes.FUNCTIONALITY,
      value: t('contactTab.contactType.0'),
    },
    { type: ContactTypes.API_INQUIRY, value: t('contactTab.contactType.1') },
    { type: ContactTypes.REFUND, value: t('contactTab.contactType.2') },
    { type: ContactTypes.DELETE_ACCOUNT, value: t('contactTab.contactType.3') },
    {
      type: ContactTypes.OTHER,
      value: t('contactTab.contactType.4'),
    },
  ];

  const handleChange = e => {
    const selectedType = e.target.value;
    if (selectedType === ContactTypes.FUNCTIONALITY) {
      showMessageV2(dispatch, t('contactTab.message.useDiscord'));
    }
    setSelectType(selectedType);

    // clear content on type change
    setContent('');
  };

  const validateEmail = email => {
    const regex = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    return regex.test(email);
  };

  const emailChange = e => {
    if (selectType !== ContactTypes.API_INQUIRY) {
      if (!checkValidLoginStatus(userId, dispatch)) return;
    }

    selectType == null ? showMessageV2(dispatch, t('contactTab.message.type')) : setEmail(e.target.value);
  };

  const contentChange = e => {
    if (selectType !== ContactTypes.API_INQUIRY) {
      if (!checkValidLoginStatus(userId, dispatch)) return;
    }

    selectType == null ? showMessageV2(dispatch, t('contactTab.message.type')) : setContent(e.target.value);
  };

  const checkAll = async () => {
    if (disableForm) return;

    if (selectType !== ContactTypes.API_INQUIRY) {
      if (!checkValidLoginStatus(userId, dispatch)) return;
    }

    if (selectType == null) {
      showMessageV2(dispatch, t('contactTab.message.type'));
      return;
    }

    if (!validateEmail(email)) {
      showMessageV2(dispatch, t('contactTab.message.email'));
      return;
    }

    if (content === '') {
      showMessageV2(dispatch, t('contactTab.message.content'));
      return;
    }

    if (selectType === ContactTypes.DELETE_ACCOUNT) {
      setOpenDeleteAccountConfirmationModal(true);
    } else {
      getResponse();
    }
  };

  const confirmDeletAccount = async res => {
    if (res) {
      getResponse();
    } else {
      setOpenDeleteAccountConfirmationModal(false);
    }
  };

  async function getResponse() {
    setLoading(true);
    const contactType = _.find(contactTypesElem, { type: selectType });

    try {
      const response = await uploadContact(
        localStorage.getItem('selectedLanguage'),
        userId,
        contactType,
        email,
        content,
        user?.currency
      );

      if (response.status === 200) {
        if (contactType.type === ContactTypes.REFUND) {
          showMessageV2(dispatch, t('contactTab.message.refundSuccess'));
        } else if (contactType.type === ContactTypes.DELETE_ACCOUNT) {
          showMessageV2(dispatch, t('contactTab.message.accountDeletedMessage'));
          navigate('/');
          dispatch(userActions.logout());
        } else {
          showMessageV2(dispatch, t('contactTab.message.success'));
        }
      } else {
        showMessageV2(dispatch, t('contactTab.message.fail'));
      }
    } catch (e) {
      showMessageV2(dispatch, t('contactTab.message.fail'));
    }
    setLoading(false);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const disableForm = selectType === ContactTypes.FUNCTIONALITY || loading;
  return (
    <>
      {openDeleteAccountConfirmationModal && (
        <DeleteAccountConfirmationModal
          onClose={() => setOpenDeleteAccountConfirmationModal(false)}
          onConfirmation={confirmDeletAccount}
        />
      )}
      <div className={classes.pageTitle}>{t('contactTab.title')}</div>
      <div className={classes.separater} />

      <div className={classes.formBlock}>
        <Select
          displayEmpty
          value={selectType}
          disabled={loading}
          disableUnderline
          variant={'standard'}
          className={`${classes.selectBlock} ${loading && classes.disabledForm}`}
          onChange={handleChange}
          renderValue={selected => {
            if (selected == null) {
              return t('contactTab.placeholder.type');
            }
            return _.find(contactTypesElem, { type: selected }).value;
          }}
          MenuProps={MenuProps}
          sx={{ fontWeight: '400' }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {contactTypesElem.map(type => (
            <MenuItem value={type.type}>
              <em>{type.value}</em>
            </MenuItem>
          ))}
        </Select>
        <OutlinedInput
          className={`${classes.inputBlock} ${disableForm && classes.disabledForm}`}
          value={email}
          placeholder={t('contactTab.placeholder.email')}
          required
          onChange={emailChange}
          disabled={disableForm}
        />
        <TextField
          multiline
          value={content}
          minRows={8}
          placeholder={
            selectType === ContactTypes.REFUND ? t('contactTab.placeholder.refund') : t('contactTab.placeholder.text')
          }
          className={`${classes.multilineInput} ${disableForm && classes.disabledForm}`}
          onChange={contentChange}
          maxRows={20}
          disabled={disableForm}
        />
        <div
          className={`${classes.button} ${
            !disableForm && selectType && (userId || selectType === ContactTypes.API_INQUIRY)
              ? ''
              : classes.disabledButton
          }`}
          onClick={checkAll}
        >
          {!loading && t('contactTab.submit')}
          {loading && <CircularProgress size="0.9rem" />}
        </div>
      </div>
    </>
  );
}

export default ContactPage;
