import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  userName: {
    marginLeft: '5px',
    fontSize: '13px',
    marginTop: '2px',
  },
  userNameContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '3px',
  },
  userIcon: {
    marginTop: '6px',
  },
  userImg: {
    width: '20px !important',
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '115px',
    },
    color: 'white',
    textDecoration: 'none',
    width: '125px',
    cursor: 'pointer',
    marginTop: '4px',
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '64px',
    minHeight: '64px',
    backgroundColor: '#1c1c1c',
  },
  topBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    width: 'calc(100% - 240px)',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '64px',
    minHeight: '64px',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #404040',
    alignItems: 'end',
    padding: '20px',
    left: '240px',
  },
  topBaerInnerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  creditContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  genCredit: {
    marginTop: '4px',
    marginBottom: '8px',
  },
  trainCredit: {},
  space: {
    flex: '1',
  },
  menuButton: {
    color: '#fff',
    marginRight: '0.5rem',
    cursor: 'pointer',
  },
  headerLoginText: {
    fontWeight: '500',
    fontSize: '14px',
    marginLeft: '0.5rem',
  },
  languageContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    cursor: 'pointer',
    display: 'flex',
    margin: '30px',
    alignItems: 'center',
  },
  selectedLanguage: {
    marginLeft: '0.7rem',
    fontWeight: '400',
  },
}));

export { useStyles };
