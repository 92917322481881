import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Switch from '@material-ui/core/Switch';
import { relative } from 'path';

const useStyles = makeStyles(theme => ({
  rootPage: {
    [theme.breakpoints.down('xs')]: {
      width: '110%',
      marginLeft: '-5%',
    },
  },
  // Page Title Styles
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '23px',
    },
    fontSize: '33px',
    color: '#fff',
    fontWeight: '400',
    marginBottom: '20px',
  },
  separater: {
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0',
    },
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.3rem 0',
  },

  // Step Header Styles
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    fontSize: '20px',
    marginBottom: '0.5rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  stepSubHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
    marginTop: '0.4rem',
    fontSize: '1rem',
    color: '#fff',
    fontWeight: '300',
  },

  // Record Button Container
  recordButtonContainer: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    marginTop: '1rem',
  },
  resultLoadingContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },
    width: '100%',
    height: 'auto',
    minHeight: '30dvh',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resultLoadingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0',
    alignItems: 'center',
    fontSize: '1rem',
    '& p': {
      [theme.breakpoints.down('xs')]: {
        lineHeight: '1rem',
      },
      textAlign: 'center',
      margin: '0.3rem 0',
      lineHeight: '1.3rem',
    },
  },
  rootContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '1.5rem',
  },
  allContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 'calc(100% - 23rem)',
    maxWidth: '65rem',
  },
  // SourceFile & Preview Container
  previewVideoContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '15rem',
    },
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '65rem',
    position: 'relative',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '30',
    top: '0',
    left: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    borderRadius: '0.5rem',
  },
  loadingAnimText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      lineHeight: '1rem',
    },
    width: '90%',
    color: '#fff',
    fontSize: '1rem',
    textAlign: 'center',
  },
  previewContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '10rem',
    },
    display: 'flex',
    width: '100%',
    height: 'calc(42dvh - 3rem)',
    backgroundColor: '#000000',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #3F3F3F',
    borderRadius: '0.5rem 0.5rem 0 0',
    borderBottom: '0',
    position: 'relative',
  },
  responsiveVideo: {
    [theme.breakpoints.down('xs')]: {
      maxHeight: '9.9rem',
    },
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: 'calc(42dvh - 3.1rem)',
    margin: '0',
  },
  videoContainer: {
    [theme.breakpoints.down('xs')]: {
      maxHeight: '9.9rem',
    },
    position: 'absolute',
    zIndex: '5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: 'calc(42dvh - 3.1rem)',
  },
  watermarkImage: {
    [theme.breakpoints.down('xs')]: {
      bottom: '0.4rem',
      left: '0.4rem',
      width: '3.5rem',
    },
    position: 'absolute',
    bottom: '0.8rem',
    left: '0.9rem',
    width: '6rem',
    height: 'auto',
    pointerEvents: 'none',
    zIndex: '5',
  },
  previewButton: {
    [theme.breakpoints.down('xs')]: {
      bottom: '0.2rem',
      right: '0.4rem',
      width: '5rem',
      fontSize: '0.7rem',
      height: '1.8rem',
      padding: '0.6rem',
    },
    position: 'absolute',
    zIndex: '5',
    bottom: '0',
    right: '0.8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '7rem',
    height: '2.5rem',
    padding: '1rem',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#000',
    fontSize: '0.9rem',
  },
  previewImage: {
    [theme.breakpoints.down('xs')]: {
      maxHeight: '10rem',
    },
    objectFit: 'contain',
    position: 'absolute',
    zIndex: '3',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: 'calc(42dvh - 3rem)',
    margin: '0',
  },
  sourceFileContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '22rem',
    },
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '65rem',
    height: '50dvh',
    backgroundColor: '#1c1c1c',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #404040',
    borderRadius: '0.5rem',
    gap: '1.5rem',
    padding: '1.5rem',
    position: 'relative',
  },
  tag: {
    position: 'absolute',
    padding: '0.3rem 0.6rem',
    borderRadius: '0.3rem 0 0.3rem 0',
    backgroundColor: '#3F3F3F',
    top: '0',
    left: '0',
    color: 'white',
    fontSize: '0.65rem',
    zIndex: '10',
  },
  buyCreditButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.5rem!important',
    },
    fontSize: '0.7rem!important',
    fontWeight: 'bold',
    marginLeft: '0.1rem',
  },

  // YouTube Info Styles
  youtubeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    fontWeight: '400',
  },
  // youtubeInfo: {
  //   display: 'flex',
  //   alignItems: 'center',
  // },
  youtubeInfoDeleteIconBox: {
    [theme.breakpoints.down('xs')]: {
      top: '0.5rem',
      right: '0.5rem',
      height: '1.8rem',
      width: '1.8rem',
      minWidth: '1.8rem',
    },
    position: 'absolute',
    zIndex: '10',
    top: '1rem',
    right: '1rem',
    height: '2.3rem',
    width: '2.3rem',
    minWidth: '2rem',
    padding: '0',
    cursor: 'pointer',
    backgroundColor: '#7F7F7F!important',
    border: '2px solid #6F6F6F',
    borderRadius: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  youtubeInfoDeleteIcon: {
    [theme.breakpoints.down('xs')]: {
      width: '1rem',
    },
    width: '1.2rem',
    height: 'auto',
    color: 'white',
  },
  fileImg: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    width: '5rem',
    height: 'auto',
    cursor: 'pointer',
  },
  dragAndDropContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '18px',
    border: '1px dashed #9A9A9A',
    borderRadius: '0.25rem',
    color: '#bdbdbd',
    fontSize: '14px',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: '2rem',
  },
  dragAndDropWrapper: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  dragAndDropText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
    fontWeight: '300',
    fontSize: '0.9rem',
    color: '#ffffff',
    textAlign: 'center',
    marginTop: '2px',
  },
  dragAndDropButton: {
    marginTop: '0.7rem',
    fontWeight: '300',
    fontSize: '0.8rem',
    borderRadius: '0.25rem',
    padding: '0.6rem',
    textAlign: 'center',
    color: '#1D1D1D',
    backgroundColor: '#F0F0F0',
  },
  hrContainter: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '20rem',
    },
    width: '100%',
    maxWidth: '26.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.7rem',
    fontWeight: '300',
    fontSize: '0.8rem',
    color: '#A4A4A4',
  },
  hrLine: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    height: '0',
    width: '12.5rem',
  },
  youtubeLinkContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      maxWidth: '20rem',
    },
    color: '#fff',
    width: '100%',
    maxWidth: '26.5rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  youtubeLinkInput: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
    color: '#fff',
    maxWidth: '20rem',
    width: '100%',
    height: '2.5rem',
    border: '1px solid #a4a4a4',
    borderRadius: '0.25rem',
    fontFamily: 'TheJamsil',
    fontSize: '0.9rem',
    '& .MuiOutlinedInput-input': {
      color: '#fff',
      fontWeight: '300',
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: '#fff !important',
      fontWeight: '300',
    },
  },
  searchButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      maxWidth: '20rem',
      width: '100%',
    },
    color: '#000',
    textColor: '#fff',
    fontFamily: 'TheJamsil',
    fontWeight: '400',
    backgroundColor: '#CAFF73!important',
    borderRadius: '0.25rem',
    width: '6rem',
    padding: '0.5rem',
    height: '2.5rem',
    cursor: 'pointer',
  },

  // Custom Controls Styles
  customControlsContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '5rem',
      flexDirection: 'column',
      gap: '0',
      paddingTop: '0.4rem',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.5rem',
    width: '100%',
    height: '4rem',
    backgroundColor: '#000',
    border: '2px solid #3F3F3F',
    borderRadius: '0 0 0.5rem 0.5rem',
    borderTop: '0',
    padding: '0 1rem',
  },

  sliderWrapper: {
    [theme.breakpoints.down('xs')]: {
      height: '2.2rem',
      width: '95%',
    },
    flexGrow: 1,
    flexBasis: '0',
    marginRight: '1rem',
    width: 'calc(90%)',
    marginLeft: 'calc(3%)',
  },

  resultPlayBarContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    display: 'flex',
    width: '90%',
    height: '2.9rem',
  },

  resultPlayPauseButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem!important',
    },
    fontSize: '2.2rem!important',
    cursor: 'pointer',
    marginRight: '1rem',
    marginBottom: '1px',
    color: '#fff',
  },

  resultSliderWrapper: {
    flexGrow: 1,
    flexBasis: '0',
    width: '100%',
    margin: 'auto',
  },

  resultSliderRoot: {
    color: '#fff',
    '& .MuiSlider-thumb': {
      [theme.breakpoints.down('xs')]: {
        width: '0.6rem',
        height: '1rem',
      },
      width: '0.7rem',
      height: '1.2rem',
      borderRadius: '2rem',
      backgroundColor: '#fff',
      boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.40)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-track': {
      border: '1px solid white',
      backgroundColor: '#fff',
    },
    '& .MuiSlider-rail': {
      backgroundColor: '#fff',
    },
  },

  // Slider Styles
  sliderRoot: {
    color: '#CAFF73',
    position: 'absolute',
    zIndex: '20',
    '& .MuiSlider-thumb': {
      [theme.breakpoints.down('xs')]: {
        height: '2.2rem',
      },
      marginTop: '0',
      height: '2.6rem',
      backgroundColor: '#fff',
      boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.40)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-thumb:nth-child(3)': {
      [theme.breakpoints.down('xs')]: {
        width: '0.6rem',
        border: '0.25rem solid #fff',
        borderTop: '0.65rem solid #fff',
        borderBottom: '0.65rem solid #fff',
        marginLeft: '-0.3rem',
      },
      width: '0.8rem',
      backgroundColor: '#3F3F3F',
      border: '0.35rem solid #fff',
      borderTop: '0.75rem solid #fff',
      borderBottom: '0.75rem solid #fff',
      borderRadius: '0.3rem 0 0 0.3rem',
      marginLeft: '-0.7rem',
    },
    '& .MuiSlider-thumb:nth-child(4)': {
      [theme.breakpoints.down('xs')]: {
        height: '2.6rem',
        width: '0.4rem',
      },
      height: '3rem',
      width: '0.6rem',
      backgroundColor: '#fff',
      borderRadius: '1rem',
      marginLeft: '0',
    },
    '& .MuiSlider-thumb:nth-child(5)': {
      [theme.breakpoints.down('xs')]: {
        width: '0.6rem',
        border: '0.25rem solid #fff',
        borderTop: '0.65rem solid #fff',
        borderBottom: '0.65rem solid #fff',
        marginLeft: '0.3rem',
      },
      width: '0.8rem',
      backgroundColor: '#3F3F3F',
      border: '0.35rem solid #fff',
      borderTop: '0.75rem solid #fff',
      borderBottom: '0.75rem solid #fff',
      borderRadius: '0 0.3rem 0.3rem 0',
      marginLeft: '0.7rem',
    },
    '& .MuiSlider-track': {
      [theme.breakpoints.down('xs')]: {
        height: '1.9rem',
      },
      height: '2.3rem',
      borderRadius: '0',
      border: 'none',
      backgroundColor: '#434343',
      borderBottomWidth: '2px',
      borderBottomColor: '#fff',
      borderBottomStyle: 'solid',
      borderTopWidth: '2px',
      borderTopColor: '#fff',
      borderTopStyle: 'solid',
      padding: '0 0.3rem',
      boxSizing: 'content-box',
      marginLeft: '-0.3rem',
    },
    '& .MuiSlider-rail': {
      [theme.breakpoints.down('xs')]: {
        height: '1.6rem',
      },
      height: '2rem',
      backgroundColor: '#434343',
      borderRadius: '0',
      padding: '0 0.3rem',
      boxSizing: 'content-box',
      marginLeft: '-0.3rem',
      // opacity: 0.5,
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: '#CAFF73',
      color: '#000',
      marginLeft: '0rem',
      '& *': {
        background: '#CAFF73',
      },
    },
    '& .MuiSlider-valueLabel:nth-child(3)': {
      backgroundColor: '#CAFF73',
      color: '#000',
      marginLeft: '-1.3rem',
      '& *': {
        background: '#CAFF73',
      },
    },
  },
  active: {},

  // thumb: {
  //   height: '1.2rem',
  //   width: '0.6rem',
  //   backgroundColor: '#fff',
  //   borderRadius: '0.2rem',
  //   marginTop: '-0.5rem',
  //   marginLeft: '-0.2rem',
  //   boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.40)',
  //   '&:focus, &:hover, &$active': {
  //     boxShadow: 'inherit',
  //   },
  // },
  // track: {
  //   height: '0.35rem',
  //   borderRadius: '1rem',
  //   backgroundColor: '#fff',
  //   marginTop: '-0.1rem',
  // },
  // rail: {
  //   height: '0.35rem',
  //   borderRadius: '1rem',
  //   backgroundColor: '#6F6F6F',
  //   marginTop: '-0.1rem',
  // },
  sliderTime: {
    [theme.breakpoints.down('xs')]: {
      gap: '0.2rem',
      fontSize: '0.7rem',
      marginBottom: '0.4rem',
      lineHeight: '14px',
    },
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.3rem',
    fontSize: '0.9rem',
    color: '#ccc',
    lineHeight: '16px',
  },

  // Faces Container
  facesContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '7rem',
    },
    width: '100%',
    height: '10.5rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#000000',
    border: '2px solid #3F3F3F',
    borderRadius: '0.5rem',
    position: 'relative',
    marginTop: '1rem',
  },
  scrollContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '7rem',
      overflowY: 'hidden',
      gap: '0.1rem',
    },
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '0.7rem',
    overflowX: 'auto',
    padding: '1rem',
    flexWrap: 'nowrap',

    '&::-webkit-scrollbar': {
      height: '0.4rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#3F3F3F',
      borderRadius: '0.25rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7F7F7F',
      borderRadius: '0.25rem',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#9F9F9F',
    },
  },
  allFacesloadingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    margin: 'auto 0',
    '& span': {
      color: 'gray',
      animation: `$fadeInOut 1.8s infinite`,
    },
  },
  '@keyframes fadeInOut': {
    '0%, 100%': { color: 'gray' },
    '50%': { color: 'black' },
  },
  noFacesFoundText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    margin: 'auto 0',
    color: 'gray',
  },
  faceBox: {
    [theme.breakpoints.down('xs')]: {
      width: '6rem',
      height: '5.3rem',
    },
    width: '7.7rem',
    height: '7.2rem',
    position: 'relative',
    flexShrink: 0,
  },
  faceDeleteIcon: {
    position: 'absolute',
    zIndex: '10',
    bottom: '0',
    right: '0',
    width: '1.2rem',
    height: 'auto',
    color: 'white',
    cursor: 'pointer',
  },
  faceFromFrame: {
    [theme.breakpoints.down('xs')]: {
      width: '4rem',
      height: '4rem',
    },
    width: '5.7rem',
    height: '5.7rem',
    left: '0',
    bottom: '0',
    objectFit: 'cover',
    position: 'absolute',
    // boxShadow: '0 0 1rem rgba(255, 255, 255, 0.6)',
    borderRadius: '50%',
    border: '2px white solid',
  },
  facesInputContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '3rem',
      height: '3rem',
      left: '2.7rem',
    },
    width: '4rem',
    height: '4rem',
    left: '3.7rem',
    top: '0',
    position: 'absolute',
    background: 'white',
    borderRadius: '50%',
    border: '2px white solid',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.8rem',
    display: 'flex',
    cursor: 'pointer',
    // boxShadow: '0 0 1rem 0 rgba(255, 255, 255, 0.40)',
  },
  facesArrowOne: {
    [theme.breakpoints.down('xs')]: {
      width: '1.2rem',
      height: '1rem',
      left: '4rem',
      top: '3.3rem',
    },
    width: '1.5rem',
    height: '1.2rem',
    left: '5.5rem',
    top: '4.6rem',
    position: 'absolute',
    transform: 'rotate(-20deg)',
  },
  facesArrowTwo: {
    [theme.breakpoints.down('xs')]: {
      width: '1.2rem',
      height: '1rem',
      left: '1.7rem',
    },
    width: '1.5rem',
    height: '1.2rem',
    left: '2.5rem',
    top: '0rem',
    position: 'absolute',
    transform: 'rotate(-178deg)',
  },
  facesArrowSelected: {
    fill: '#CAFF73',
  },
  selectedFace: {
    [theme.breakpoints.down('xs')]: {
      width: '3rem',
      height: '3rem',
    },
    width: '4rem',
    height: '4rem',
    objectFit: 'cover',
    borderRadius: '50%',
    border: '2px white solid',
  },
  selectedFaceSelected: {
    border: '3px solid #CAFF73',
    boxShadow: '0 0 1rem 0 rgba(255, 255, 255, 0.6)',
  },
  facesPlus: {
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
    width: '1.8rem',
    height: '1.8rem',
    cursor: 'pointer',
  },
  facesInput: {
    display: 'none',
  },
  verticalDivider: {
    height: '100%',
    backgroundColor: '#ccc',
    margin: '0 0.6rem',
    padding: '1px',
  },

  // Setting, Start Button
  buttonContainer: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  trimButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  prevButtonContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: '2.2rem',
      padding: '0.5rem 0.7rem',
      fontSize: '0.7rem',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '7rem',
    height: '2.5rem',
    padding: '1rem',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#000',
    fontSize: '0.9rem',
    gap: '0.5rem',
    marginBottom: 'auto',
  },
  startContainer: {
    [theme.breakpoints.down('xs')]: {
      gap: '0.3rem',
    },
    display: 'flex',
    gap: '0.6rem',
    alignItems: 'center',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: '0.5rem',
    fontSize: '0.8rem',
    color: '#fff',
  },
  tooltipContainerStepOne: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: '0.2rem',
    fontSize: '0.5rem',
    color: '#fff',
  },
  tooltipImg: {
    [theme.breakpoints.down('xs')]: {
      width: '0.65rem',
      height: '0.85rem',
      marginLeft: '0.15rem',
      marginTop: '-2px',
    },
    width: '0.75rem',
    height: '0.95rem',
    marginLeft: '0.3rem',
  },
  redCoin: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.57rem',
    },
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
    color: '#ff0000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  coin: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.57rem',
    },
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  redCoinStepOne: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.57rem',
    },
    fontFamily: 'TheJamsil',
    fontSize: '0.65rem',
    color: '#ff0000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  coinStepOne: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.57rem',
    },
    fontFamily: 'TheJamsil',
    fontSize: '0.65rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },

  // Settings Container
  settingsContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '0.5rem',
    height: 'fit-content',
    color: '#fff',
    backgroundColor: '#000',
    position: 'relative',
  },
  // Settings Container
  settingsContainerModal: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '0.5rem',
    height: 'fit-content',
    color: '#fff',
    backgroundColor: '#000',
    position: 'relative',
  },

  // Modal
  modal: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#000',
    borderRadius: '0.5rem',
    outline: 'none',
  },

  // Footer
  footer: {
    [theme.breakpoints.down('xs')]: {
      bottom: '1rem',
      position: 'relative',
      marginTop: '2.5rem',
    },
    display: 'flex',
    gap: '0.8rem',
    position: 'absolute',
    bottom: '1.5rem',
    justifyContent: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  progressIndicator: {
    width: '0.6rem',
    height: '0.6rem',
    backgroundColor: '#3F3F3F',
    borderRadius: '50%',
  },

  upgradeButton: {
    width: 'fit-content',
    padding: '0.6rem 1rem',
    fontSize: '0.8rem',
    backgroundColor: '#CAFF73',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    margin: '0 auto',
    marginTop: '1.5rem',
  },

  upgradeDialog: {
    fontFamily: 'TheJamsil',
    '& .MuiPaper-root': {
      backgroundColor: '#1E1E1E',
      padding: '1.2rem 1.5rem',
    },
  },
  dialogTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: '0.9rem',
    paddingTop: '0.5rem',
  },
  dialogText: {
    color: '#AAAAAA',
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '0.8rem',
    paddingTop: '0.6rem',
    maxWidth: '18rem',
  },
  croppedFaceImage: {
    [theme.breakpoints.down('xs')]: {
      width: '4.2rem',
      height: '4.2rem',
    },
    width: '5rem',
    height: '5rem',
    objectFit: 'cover',
    border: '2px solid #fff',
    borderRadius: '50%',
    transition: 'transform 0.2s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
      border: '2px solid #CAFF73',
      boxShadow: '0 0 1rem 0 rgba(202, 255, 115, 0.70)',
    },
  },
  faceSelectionModal: {
    '& .MuiDialog-paper': {
      backgroundColor: '#000',
      color: '#fff',
      border: '1px solid #3f3f3f',
      borderRadius: '0.5rem',
      padding: '1.5rem',
      fontWeight: '400',
    },
  },
  faceSelectionModalTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
    fontSize: '1.2rem',
    borderBottom: `1px solid #fff`,
    paddingBottom: '1rem',
  },
  faceSelectionModalBtnContainer: {
    display: 'none',
  },
  faceSelectionBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    backgroundColor: 'white!important',
    gap: '0.8rem',
  },
  uploadImage: {
    width: '1.2rem',
    height: '1.2rem',
  },
  faceSelectionModalContent: {
    [theme.breakpoints.down('xs')]: {
      width: '16rem',
      padding: '0.5rem 0.5rem 0 0.5rem',
    },
    display: 'flex',
    justifyContent: 'center',
    width: '24.5rem',
    height: '12rem',
    marginTop: '0.5rem',
    padding: '0.6rem 0.6rem 0 0.6rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    scroll: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.3rem',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7F7F7F',
      borderRadius: '0.25rem',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#9F9F9F',
    },
  },

  // distance slider

  distanceContainter: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.35rem',
    maxWidth: '20rem',
    width: '100%',
  },
  settingsInnerBoxSub: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  settingsText: {
    color: '#B7B7B7',
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
  },
  settingsRatio: {
    color: '#fff',
    fontSize: '0.8rem',
    fontFamily: 'TheJamsil',
    border: '1.5px solid #6F6F6F',
    borderRadius: '0.25rem',
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  leastFrameInput: {
    textAlign: 'right',
    color: '#fff',
    backgroundColor: 'black',
    fontSize: '0.8rem',
    fontFamily: 'TheJamsil',
    border: '1.5px solid #6F6F6F',
    borderRadius: '0.25rem',
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: '5rem',
  },
  bboxLengthText: {
    position: 'absolute',
    zIndex: '10',
    color: '#fff',
    fontSize: '0.8rem',
    bottom: '0',
    right: '0',
  },
  settingsTextFieldInputProps: {
    textAlign: 'right',
    color: '#fff',
    fontSize: '0.8rem',
    fontFamily: 'TheJamsil',
    padding: '0',
    margin: '0',
  },
  settingsTextField: {
    color: '#fff',
    borderBottom: '1.5px solid #6F6F6F',
    margin: '0',
    padding: '0',
    width: '1rem',
    height: '1rem',
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield', // Firefox
    },
  },
  settingsRoot: {
    marginTop: '0',
    '& .MuiSlider-thumb': {
      height: '2rem',
      width: '0.5rem',
      backgroundColor: '#fff',
      borderRadius: '0.25rem',
      marginTop: '0rem',
      boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.40)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: 'inherit',
      },
      '&.Mui-disabled': {
        height: '2rem',
        width: '0.5rem',
        backgroundColor: '#fff',
        borderRadius: '0.25rem',
        marginTop: '0rem',
        marginLeft: '-0.25rem',
        boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.40)',
      },
    },
    '& .MuiSlider-track': {
      height: '2rem',
      borderRadius: '0.25rem',
      backgroundColor: '#B7B7B7',
      border: '0',
    },
    '& .MuiSlider-rail': {
      height: '2rem',
      borderRadius: '0.25rem',
      backgroundColor: '#6F6F6F',
    },
  },
  createNewButton: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '14rem',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '2.5rem',
    padding: '1rem',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    backgroundColor: '#CAFF73',
    color: '#000',
    fontSize: '0.9rem',
    margin: '2rem auto',
    animation: `$fadeOut 5s`,
  },
  '@keyframes fadeOut': {
    '0%': { opacity: '0' },
    '50%': { opacity: '0' },
    '100%': { opacity: '1' },
  },
}));

const SettingsSwitch = styled(Switch)(() => ({
  padding: 8,
  marginRight: '-1.5rem',
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: '#6F6F6F',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#CAFF73',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
}));

export { useStyles, SettingsSwitch };
