import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '730px',
    minWidth: '299px',
    width: '100%',
    alignSelf: 'center',
    marginBottom: '90px',
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '23px',
    },
    fontSize: '33px',
    fontWeight: '400',
    color: '#fff',
    marginBottom: '35px',
  },
  section: {
    marginBottom: '20px',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    fontSize: '16px',
    marginBottom: '20px',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: '0',
  },
  stepHeaderChecked: {
    width: '19px',
    height: '18px',
    marginRight: '9px',
  },
  stepHeaderNum: {
    marginRight: '9px',
    fontSize: '13px',
    borderRadius: '50%',
    border: '2px solid #fff',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2px',
  },
  card: {
    display: 'flex',
    backgroundColor: '#292929',
    border: '1px solid #404040',
    borderRadius: '8px',
    height: '76px',
  },
  uploadContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '15px 10px',
    },
    width: '100%',
    border: '1px solid #404040',
    borderRadius: '8px',
    backgroundColor: '#292929',
    padding: '20px',
  },
  inputSourceButtonContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '15px',
    },
    display: 'flex',
    marginBottom: '25px',
    alignItems: 'center',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    fontSize: '14px',
    fontWeight: '400',
    color: '#9a9a9a',
    marginRight: '13px',
    cursor: 'pointer',
  },
  selected: {
    fontWeight: '400',
    color: '#ffffff',
  },
  tooltipImg: {
    [theme.breakpoints.down('xs')]: {
      width: '14px',
    },
    margin: '0 10px',
    width: '14px',
  },
  dragAndDropContainer: {
    marginTop: '0.5rem',
    backgroundColor: '#292929',
  },
  dragAndDrop: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 0',
    justifyContent: 'center',
  },
  dragAndDropText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
      lineHeight: '14px',
    },
    fontWeight: '300',
    fontSize: '16px',
    color: '#ffffff',
    textAlign: 'center',
    lineHeight: '19px',
  },
  addButtonImg: {
    [theme.breakpoints.down('xs')]: {
      width: '17px',
      height: '17px',
      marginRight: '5px',
    },
    width: '25px',
    height: '25px',
    marginRight: '10px',
  },
  youtubeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
  },
  youtubeInfo: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
    },
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  youtubeInfoDeleteIcon: {
    width: '17px',
    marginLeft: '8px',
    cursor: 'pointer',
  },
  youtubeLinkInput: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px !important',
      padding: '5px !important',
    },
    width: '100%',
    border: '1px solid #9A9A9A',
    height: '48px',
    borderRadius: '8px !important',
    color: '#9A9A9A !important',
  },
  recordButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  youtubeLinkButton: {
    [theme.breakpoints.down('xs')]: {
      width: '70px !important',
      fontSize: '10px !important',
    },
    width: '100px !important',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '35px',
  },
  listItemAvatar: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '30px',
    },
    minWidth: '40px',
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      width: '20px !important',
      height: '20px !important',
    },
    width: '23px !important',
    height: '23px !important',
  },
  audioIcon: {
    [theme.breakpoints.down('xs')]: {
      width: '15px',
      height: '15px',
    },
    width: '18px',
    height: '18px',
  },
  listItemText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px!important',
      maxWidth: '165px',
    },
    fontSize: '14px!important',
    color: '#fff',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  dragAndDropList: {
    paddingTop: '10px !important',
    paddingBottom: '0!important',
  },
  extensionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  extensionButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: '300',
    color: '#9a9a9a',
    marginRight: '15px',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '74px',
    height: '36px',
    border: '1px solid #404040',
    backgroundColor: '#292929',
  },
  extensionSelected: {
    fontWeight: '400',
    border: '1px solid #B9FF66',
    color: '#B9FF66',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    fontSize: '16px',
    marginLeft: '16px',
    lineHeight: '22px',
    color: '#fff',
    fontWeight: '300',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
      fontSize: '11px',
      minWidth: '100px',
    },
    backgroundColor: '#CAFF73',
    padding: '10px 30px',
    fontSize: '16px',
    textAlign: 'center',
    borderRadius: '8px',
    minWidth: '120px',
    cursor: 'pointer',
  },
  labelInput: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px !important',
    },
    fontSize: '16px !important',
    padding: '0 8px',
    width: '100%',
    height: '36px',
    color: '#9a9a9a',
    padding: '18px 10px',
    backgroundColor: '#292929',
    border: '1px solid #9A9A9A',
    borderRadius: '8px !important',
    '&:focus': {
      outline: 'none',
    },
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#686868',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  noMargin: {
    margin: '0',
  },
  greyout: {
    color: '#888888',
    '& $stepHeaderNum': {
      border: '2px solid #888888',
    },
  },
  disabledButton: {
    backgroundColor: '#A4A4A4!important',
    cursor: 'not-allowed',
  },
  progressButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px 10px 15px',
      fontSize: '11px',
      minWidth: '95px',
    },
    backgroundColor: '#A4A4A4!important',
    padding: '10px 20px 10px 30px',
    fontSize: '16px',
    textAlign: 'center',
    borderRadius: '8px',
    minWidth: '120px',
    cursor: 'not-allowed',
    display: 'flex',
  },
  loadingCircle: {
    [theme.breakpoints.down('xs')]: {
      width: '11px!important',
      height: '11px!important',
      marginLeft: '5px',
    },
    marginLeft: '5px',
    width: '15px!important',
    height: '15px!important',
  },
}));

export { useStyles };
